import { Flex } from '@workwhile/ui'
import React from 'react'
import { TimeRangeFilters } from './TimeRangeFilters/TimeRangeFilters'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { filtersSchema, FiltersSchema } from './filtersSchema'
import { SearchButton } from './SearchButton'
import { ShiftFilters } from './ShiftFilters/ShiftFilters'
import { CompanyFilters } from './CompanyFilters/CompanyFilters'
import { useShiftsContextValue } from '../../context/ShiftsProvider'

export const ShiftListFilters = () => {
  const {
    state: { timeRangeFilters, shiftFilters, companyFilters },
  } = useShiftsContextValue()

  const methods = useForm<FiltersSchema>({
    resolver: zodResolver(filtersSchema),
    reValidateMode: 'onChange',
    mode: 'all',
    defaultValues: {
      timeRange: {
        type: null,
        dateRange: {
          from: null,
          to: null,
        },
      },
      shift: {
        shiftIdUserInput: undefined,
        ids: [],
        listingIdUserInput: undefined,
        listingIds: [],
        marketCodeUserInput: undefined,
        marketCodes: [],
        scheduleRateMin: null,
        scheduleRateMax: null,
        fillRateMin: null,
        fillRateMax: null,
      },
      company: {
        companyIdUserInput: undefined,
        companyIds: [],
        companyGroupIdUserInput: undefined,
        companyGroupIds: [],
      },
    },
    values: {
      timeRange: {
        type: timeRangeFilters.type,
        dateRange: {
          from: timeRangeFilters.startDate,
          to: timeRangeFilters.endDate,
        },
      },
      shift: {
        shiftIdUserInput: undefined,
        ids: shiftFilters.shiftIds.map((id) => ({ id })),
        listingIdUserInput: undefined,
        listingIds: shiftFilters.listingIds.map((id) => ({ id })),
        marketCodeUserInput: undefined,
        marketCodes: shiftFilters.marketCodes.map((code) => ({ code })),
        scheduleRateMin: shiftFilters.scheduleRateMin,
        scheduleRateMax: shiftFilters.scheduleRateMax,
        fillRateMin: shiftFilters.fillRateMin,
        fillRateMax: shiftFilters.fillRateMax,
      },
      company: {
        companyIdUserInput: undefined,
        companyIds: companyFilters.companyIds.map((id) => ({ id })),
        companyGroupIdUserInput: undefined,
        companyGroupIds: companyFilters.companyGroupIds.map((id) => ({ id })),
      },
    },
  })

  return (
    <FormProvider {...methods}>
      <Flex
        as="form"
        flexDirection="row"
        gap={2}
        alignItems="center"
        onSubmit={(event) => {
          event.preventDefault()
        }}
      >
        <TimeRangeFilters />
        <ShiftFilters />
        <CompanyFilters />
        <SearchButton />
      </Flex>
    </FormProvider>
  )
}
