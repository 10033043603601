import {
  Flex,
  Text,
  Link,
  useAppTheme,
  Tooltip,
  Pluralize,
} from '@workwhile/ui'
import React from 'react'
import { ColumnDef } from '@tanstack/react-table'
import {
  formatDistanceStrict,
  formatDistanceToNowStrict,
  isBefore,
  isSameYear,
} from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'
import { formatPercentage } from 'lib/percentage'
import { getSafeNumber } from 'lib/number'
import { ArrowRight, Ban } from 'lucide-react'
import { useCompanyDetailContextValue } from '../../../../context/CompanyDetailProvider'
import { getTierTransitionLabel } from 'shared'

type Shifts = ReturnType<
  typeof useCompanyDetailContextValue
>['state']['shifts']['data']['items']

const TimeColumn: ColumnDef<Shifts[number]> = {
  header: 'Time',
  accessorKey: 'startsAt',
  enableResizing: true,
  size: 256,
  cell: ({ row }) => {
    const isCurrentYear = isSameYear(row.original.startsAt, new Date())
    const timeZone = row.original.location.address.timezone
    const startsOn = formatInTimeZone(
      row.original.startsAt,
      timeZone,
      isCurrentYear
        ? 'EEE MMM, dd, hh:mm a zz'
        : 'EEE MMM, dd, yyyy, hh:mm a zz'
    )
    const startsAt = formatInTimeZone(
      row.original.startsAt,
      timeZone,
      'MMM, dd, yyyy, hh:mm a'
    )
    const distance = formatDistanceToNowStrict(row.original.startsAt, {
      addSuffix: true,
    })
    return (
      <Flex as="span" flexDirection="column" title={startsAt}>
        <Text as="span">{startsOn}</Text>
        <Text as="span" color="lightText">
          ({distance})
        </Text>
      </Flex>
    )
  },
}

const CompanyColumn: ColumnDef<Shifts[number]> = {
  header: 'Company',
  accessorKey: 'company.name',
  minSize: 180,
  maxSize: 200,
  cell: ({ row }) => {
    return (
      <Text>
        {row.original.company.name} (
        <Link to={`/companies/${row.original.company.id}`}>
          {row.original.company.id}
        </Link>
        )
      </Text>
    )
  },
}

const LocationColumn: ColumnDef<Shifts[number]> = {
  header: 'Location',
  accessorKey: 'location.name',
  minSize: 200,
  maxSize: 256,
  cell: ({ row }) => {
    if (!row.original.location) {
      return <Text>-</Text>
    }
    const { address } = row.original.location

    if (!address) {
      return <Text>{row.original.location.name}</Text>
    }

    return (
      <Text>
        {row.original.locationless ? 'Locationless' : null}
        {row.original.location.name}
        <br />
        {address.city}, {address.state}
      </Text>
    )
  },
}

const PositionColumn: ColumnDef<Shifts[number]> = {
  header: 'Position',
  accessorKey: 'position.name',
  minSize: 200,
  maxSize: 256,
  cell: ({ row }) => {
    return (
      <Text>
        {row.original.position.name} (
        <Link to={`/position_editor/?positionId=${row.original.position.id}`}>
          {row.original.position.id}
        </Link>
        )
      </Text>
    )
  },
}

const ScheduleRateColumn: ColumnDef<Shifts[number]> = {
  header: 'Schedule Rate',
  minSize: 200,
  cell: ({ row }) => {
    if (row.original.cancelledAt) {
      return <Text>-</Text>
    }

    const scheduleRate = row.original.scheduleRate
    const scheduleRateWorkers = row.original.numScheduleRateWorkers

    const safeNumWorkersNeeded = getSafeNumber(row.original.workersNeeded)
    const hasCushion = row.original.numCushionWorkersNeeded > 0

    const description = `${scheduleRateWorkers} out of ${safeNumWorkersNeeded}${hasCushion ? ` + ${row.original.numCushionWorkersNeeded} cushion` : ''}`
    return (
      <Flex as="span" flexDirection="column">
        <Text as="span">{formatPercentage(scheduleRate)}</Text>
        <Text as="span" color="lightText">
          {description}
        </Text>
      </Flex>
    )
  },
}

const FillRateColumn: ColumnDef<Shifts[number]> = {
  header: 'Fill Rate',
  minSize: 120,
  cell: ({ row }) => {
    if (isBefore(new Date(), row.original.startsAt)) {
      return <Text>-</Text>
    }
    if (row.original.cancelledAt) {
      return <Text>-</Text>
    }

    const safeNumWorkersNeeded = getSafeNumber(row.original.workersNeeded)
    const fillRate = row.original.fillRate
    const fillRateWorkers = row.original.numFillRateWorkers
    const hasCushion = row.original.numCushionWorkersNeeded > 0
    const description = `${fillRateWorkers} out of ${safeNumWorkersNeeded}${hasCushion ? ` + ${row.original.numCushionWorkersNeeded} cushion` : ''}`

    return (
      <Flex as="span" flexDirection="column">
        <Text as="span">{formatPercentage(fillRate)}</Text>
        <Text as="span" color="lightText">
          {description}
        </Text>
      </Flex>
    )
  },
}

const TierColumn: ColumnDef<Shifts[number]> = {
  header: 'Tier & Listing',
  accessorKey: 'listingTiers',
  minSize: 256,
  cell: ({ row }) => {
    if (!row.original.primaryListingTier) {
      return <Text>N/A</Text>
    }

    return (
      <Flex as="span" flexDirection="column">
        <Text as="span">
          {getTierTransitionLabel(row.original.primaryListingTier.tier)}
        </Text>
        <Text as="span" color="lightText">
          Listing: {row.original.primaryListingTier.id}
        </Text>
        {row.original.numOtherListings > 0 ? (
          <>
            <Text as="span" color="lightText" mt={1}>
              +{' '}
              <Pluralize
                count={row.original.numOtherListings}
                oneText="more listing"
                manyText="more listings"
              />
            </Text>
          </>
        ) : null}
      </Flex>
    )
  },
}

const MarketColumn: ColumnDef<Shifts[number]> = {
  header: 'Market',
  accessorKey: 'market.code',
  cell: ({ row }) => {
    return <Text>{row.original.market}</Text>
  },
}

export const selectableColumns = [
  {
    id: 'time',
    label: 'Time',
    columnDef: TimeColumn,
  },
  {
    id: 'company',
    label: 'Company',
    columnDef: CompanyColumn,
  },
  {
    id: 'location',
    label: 'Location',
    columnDef: LocationColumn,
  },
  {
    id: 'position',
    label: 'Position',
    columnDef: PositionColumn,
  },
  {
    id: 'schedule-rate',
    label: 'Schedule Rate',
    columnDef: ScheduleRateColumn,
  },
  {
    id: 'fill-rate',
    label: 'Fill Rate',
    columnDef: FillRateColumn,
  },
  {
    id: 'tier',
    label: 'Tier & Listing(s)',
    columnDef: TierColumn,
  },
  {
    id: 'market',
    label: 'Market',
    columnDef: MarketColumn,
  },
] as const

export type SelectableColumns = (typeof selectableColumns)[number]['id']

/**
 *
 *
 *  Non-selectable columns go here. They're exported out to be used directly in the table.
 *
 *
 */

export const IDColumn: ColumnDef<Shifts[number]> = {
  header: 'ID',
  accessorKey: 'id',
  minSize: 50,
  cell: ({ row }) => {
    const { colors } = useAppTheme()
    if (row.original.cancelledAt) {
      const cancelledAt = formatInTimeZone(
        row.original.cancelledAt,
        row.original.location.address.timezone,
        'MMM, dd, yyyy, hh:mm a'
      )
      const cancelledAtDistance = formatDistanceToNowStrict(
        row.original.cancelledAt,
        {
          addSuffix: true,
        }
      )

      const distanceToStartTime = formatDistanceStrict(
        row.original.cancelledAt,
        row.original.startsAt,
        {
          addSuffix: false,
        }
      )

      return (
        <Flex alignItems="center" gap={2}>
          <Text as="span">{row.original.id}</Text>{' '}
          <Tooltip
            content={
              <Text as="span">
                Shift was cancelled at <br />
                {cancelledAt} ({cancelledAtDistance}) <br />
                with {distanceToStartTime} of notice
              </Text>
            }
            variant="neutral"
          >
            <Ban size={14} color={colors.error100} />
          </Tooltip>
        </Flex>
      )
    }

    return <Text>{row.original.id}</Text>
  },
}

export const DetailColumn: ColumnDef<Shifts[number]> = {
  header: 'Detail',
  size: 85,
  cell: ({ row }) => {
    const { colors } = useAppTheme()
    return (
      <Link to={`/shifts/${row.original.id}`}>
        View <ArrowRight size={14} color={colors.primary} />
      </Link>
    )
  },
}
